import { OrderItemType, OrderType } from "components/products/ProductType";
import { useEffect, useState } from "react";


type OrderProps={
    order:OrderType
}

type OrderItemProps = {
    item:OrderItemType
}

const OrderSummary:React.FC<OrderProps> = ({order}) => {

    const [itemsArray,setItemArray] = useState<any[]>([])

    useEffect(()=>{
        let rows:any[] = [];
        order.items?.forEach((el,index)=>{
            rows.push(
                <OrderItem
                    key={index}
                    item={el}
                />
            )

        })
        setItemArray(rows);
    },[order])

    const OrderItem:React.FC<OrderItemProps> = ({item})=>{

        return (
            <>
            
            <div>{ item.product.name}</div>
            <div>{ item.quantity }</div>
            </>
        )
    }

    return (
        <div className="pdpt-bg">
            {order.status==1 && 
                <div className="pdpt-title">
                    <h6>{order.deliverydate}</h6>
                </div> 
            }

            <div className="order-body10">
                <ul className="order-dtsll">
                    <li>
                        <div className="order-dt-img">
                            
                        </div>
                    </li>
                    <li>
                        <div className="order-dt47">
                            Order Id: <span>{order.id}</span>
                            Ordered Date: <span>{order.created_at}</span>
                            <h4>
                                {itemsArray}
                            </h4>
                            {order.status==3 && 
                                <p>Delivered : {order.deliverydate}</p>
                            }
                            {order.status==0 && 
                                <p>Order Initiated </p>
                            }
                            {order.status==1 && 
                                <p>Order Confirmed </p>
                            }
                            
                        </div>
                    </li>
                </ul>
                
                
                
            </div>
        </div>
    )
}

export default OrderSummary;