import { fetchUserOrderTransactions } from "api/General";
import { OrderItemType, OrderType, PaymentType } from "components/products/ProductType";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { assignOrderTransactions, orders } from "redux/slices/ordersSlice";
import OrderSummary from "./OrderSummary";





type OrderItemProps = {
    orderItem:OrderItemType
}

type PaymentItemProps = {
    payment:PaymentType;
}

const OrdersComponent:React.FC = ()=>{

    const dispatch = useDispatch();
    
    
    const allOrders = useAppSelector(orders)
    const [orderElements,setOrderElements] = useState<any[]>([])
    const [payments,setPayments] = useState<any[]>([])
    const [orderDetailArray,setOrderDetailArray] = useState<any[]>([])

    
    let loadFlag = false;
    useEffect(()=>{
        if(!loadFlag){
            loadFlag = true
            
            console.log("a");
            fetchData();
        }
    },[])

    useEffect(()=>{
        
        let rows:any[] = [];
        console.log("Setting the orders")
        allOrders?.forEach((el,index)=>{
            rows.push(
                <OrderSummary key={index}
                    order={el}
                   
                />
            )
        })
        setOrderDetailArray(rows)
        console.log("Setting the orders1")
        console.log(rows)
        console.log()
    },[allOrders])

    const fetchData = async ()=>{
        
        const res = await fetchUserOrderTransactions({})
        /*setOrderTransaction(res);
        console.log("After fetching")
        console.log(res)
        console.log(orderTransaction)
        let localOrderTransaction:OrderTransactionType = res as OrderTransactionType
        console.log(localOrderTransaction)
        console.log(orderTransaction)
        */
        loadFlag = false
        console.log(res['ordertransaction'])
        dispatch(assignOrderTransactions(res))
    }
    
    const PaymentElement:React.FC<PaymentItemProps> = ({payment})=>{
        return (
            <div>
                <span>Amount{payment.amount}</span>
                <span>Mode{payment.mode}</span>
            </div>
        )
    }

    

    return (
        <div className="col-xl-9 col-lg-8 col-md-12">
            <div className="dashboard-right">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-title-tab">
                            <h4><i className="uil uil-box"></i>My Orders</h4>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        {orderDetailArray}
                        
                    </div>								
                </div>
            </div>
        </div>
    )
}

export default OrdersComponent;