
export type SiteDataMenuType = {
  name: string;
  href: string;
  submenu:SiteDataMenuType[];
};

export type BannerTypes = {
  banner1:number;
  banner2:number;
}

export type SiteDataType = {
  sitename:string;
  headerlogourl:string;
  topmenu:SiteDataMenuType[];
  config:BannerTypes;
}


export const siteData:SiteDataType = 
{
  sitename: "Dwain India",
  headerlogourl: "https://dwainindiaimages.s3.ap-south-1.amazonaws.com/site/dwainlogo.png",
  topmenu : [
    {
      name:"Home",
      href:"#",
      submenu:[]
    },
    {
      name:"Grocery",
      href:"#",
      submenu:[
        {
          name:"Fresh Vegetables",
          href:"#",
          submenu:[]
        },
        {
          name:"Fresh Fruits",
          href:"#",
          submenu:[]
        },
        {
          name:"Dairy & Bakery",
          href:"#",
          submenu:[]
        },
        {
          name:"Food Grains & Staples",
          href:"#",
          submenu:[]
        },
        {
          name:"Snacks & Packed Foods",
          href:"#",
          submenu:[]
        },
        {
          name:"Edible Oil & Spices",
          href:"#",
          submenu:[]
        },
        {
          name:"All Food & Gourmet",
          href:"#",
          submenu:[]
        },
        {
          name:"Dry Fruit & Other",
          href:"#",
          submenu:[]
        }
      ]
    },
    
    {
      name:"Clothing",
      href:"#",
      submenu:[
        {
          name:"Mens",
          href:"#",
          submenu:[]
        },
        {
          name:"Womens",
          href:"#",
          submenu:[]
        },
        {
          name:"Kids",
          href:"#",
          submenu:[]
        },
      ]
    },
    {
      name:"Home Essentials",
      href:"#",
      submenu:[
        {
          name:"Home Decor",
          href:"#",
          submenu:[]
        },
        {
          name:"Handmade",
          href:"#",
          submenu:[]
        },
        {
          name:"Curtains & Pillow",
          href:"#",
          submenu:[]
        },
        {
          name:"Furniture",
          href:"#",
          submenu:[]
        },
      ]
    },
    {
      name:"Contact",
      href:"#",
      submenu:[]
    },
  ],
  config:{
    banner1:1,
    banner2:2
  }
}

